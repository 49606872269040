import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import { Trans, withTranslation } from "react-i18next"
import { APP_ROUTES } from "../constants/route"
import useCurrentLang from "../hooks/useCurrentLang"

const JourneyCard = ({
  title,
  illustration,
  subtitle,
  color,
  slug,
  category,
  horizontalSpace,
  subtitleMinHeight,
  completed,
  width = "100%",
  ...rest
}) => {
  const { code: langCode } = useCurrentLang()
  return (
    <div
      style={{ backgroundColor: color, width: width }}
      className={cx("card__wrapper", {
        "card__wrapper--horizontal-space": horizontalSpace,
      })}
    >
      {category && (
        <div
          className="card__header"
          style={{ backgroundColor: category.color, color: color }}
        >
          {category.title}
        </div>
      )}

      {completed ? (
        <div className="progress__point__circle progress__point__circle--small ">
          <svg width="14" height="12" xmlns="http://www.w3.org/2000/svg">
            <g fill="#000" fillRule="evenodd">
              <path d="M2.05 5.707L6.293 9.95l-1.414 1.414L.636 7.121z" />
              <path d="M13.364 2.879l-8.485 8.485L3.464 9.95l8.486-8.486z" />
            </g>
          </svg>
        </div>
      ) : null}
      <img
        className="card__image margin--top-24"
        src={illustration.file.url}
        alt=""
      />
      <div className="card__text">
        <h2 className="card__title no-margin--bottom margin--top-16">
          {rest[`title_${langCode === "zh" ? "hk" : langCode}`] || title}
        </h2>
        <p
          className={cx("card__subtitle", {
            "card__subtitle--min-height": subtitleMinHeight,
          })}
        >
          {rest[`subtitle_${langCode === "zh" ? "hk" : langCode}`]
            ? rest[`subtitle_${langCode === "zh" ? "hk" : langCode}`][
                `subtitle_${langCode === "zh" ? "hk" : langCode}`
              ]
            : subtitle.subtitle}
        </p>
        <Link
          to={`${APP_ROUTES.journeys}/${slug}`}
          className="card__button button"
        >
          <Trans i18nKey="journey.card_explore" />
        </Link>
      </div>
    </div>
  )
}

export default withTranslation()(JourneyCard)
