import React, { useState, useEffect } from "react"
import { useQuery } from "@apollo/react-hooks"
import LayoutContainer from "../components/layout"
import JourneyCard from "../components/journey-card"
import Throbber from "../components/throbber"
import { Trans, withTranslation, useTranslation } from "react-i18next"
import { Journey, USER_ID_AND_COMPANY } from "../gql"
import TETorch from "./../assets/brands/te/te-torch.png"
import { getDataInDifferentSets } from "../utilities/helpers"
import { getUserJourneysAndActivities } from "../queries/user"

const DISABLE_ACTIVE_JOURNEY = false
const Journeys = ({ location }) => {
  const { t } = useTranslation()
  const [myData, setMyData] = useState(null)
  const {
    isLoading: uJALoading,
    isError: uJAIsError,
    data: uJAData,
    error: uJAError,
    // isFetching: upIsFetching,
    refetch: fetchUJAData,
  } = getUserJourneysAndActivities({
    service: "",
    relativeUrl: "/admin-journey",
    params: {},
  })
  const { data: myCompanyData } = useQuery(USER_ID_AND_COMPANY)

  const { nodes: allJourneys } = Journey()

  const getActiveJourneys = () => {
    // Get all the active journeys
    const activeJourneys = DISABLE_ACTIVE_JOURNEY
      ? allJourneys.filter(journey => {
          const myJourney = myData.me.journey.find(myJourneys => {
            return myJourneys.contentfulId === journey.contentful_id
          })
          if (myJourney) return myJourney.active
          return false
        })
      : []

    // Get the company specific activities (if there is any)
    const companySpecific = allJourneys.filter(journey => {
      if (journey.companySpecific) {
        return (
          journey.companySpecific.contentful_id === myCompanyData.me.company
        )
      }
      return false
    })

    // Merge the recommended journeys with the company specific and active journeys.
    // This is because the recommended and company specific journeys should always be under "Your Journeys"
    const mergedJourneys = [...companySpecific, ...activeJourneys]
    // Run through a Set to remove duplicates (if a recommended journey is also active)
    const mergedJourneysSet = [...new Set(mergedJourneys)]

    const returnedDataSets = getDataInDifferentSets(mergedJourneysSet)

    return returnedDataSets
  }

  const returnTranslatedWeekNumber = weekNumber => {
    return t(`journey.number${weekNumber}`)
  }

  useEffect(() => {
    fetchUJAData()
  }, [])

  useEffect(() => {
    if (uJAData && uJAData.data) {
      setMyData(uJAData.data)
    }
  }, [uJAData])

  useEffect(() => {
    if (uJAIsError) {
      console.log("Error received in userProgress: ", uJAError)
    }
  }, [uJAIsError])

  return (
    <LayoutContainer path={location.pathname} seoTitle="Journey overview">
      {uJALoading ? (
        <Throbber relative loadingText="Loading..." />
      ) : (
        <>
          {myData &&
            myData.me &&
            getActiveJourneys().map(journey => (
              <div key={journey.id} className="margin--bottom-100">
                <div className="flex-center">
                  <img src={TETorch} alt="te-torch" className="torch-image" />
                  <h1 className="big-title uppercase center no-margin--bottom">
                    {journey.name === "Before kick-off" ? (
                      <Trans i18nKey={"journey.getStarted"} />
                    ) : (
                      <Trans
                        i18nKey={"journey.weekTitle"}
                        values={{
                          weekNumber: returnTranslatedWeekNumber(
                            journey.weekNumber
                          ),
                        }}
                      />
                    )}
                  </h1>
                </div>
                <div className="row">
                  <p className="col s12 l8 offset-l2 center text">
                    {journey.name === "Before kick-off" ? (
                      <Trans i18nKey={"journey.sub_text_start"} />
                    ) : (
                      <Trans i18nKey={"journey.sub_text"} />
                    )}
                  </p>
                </div>

                <div className="row card__grid">
                  <>
                    {journey.data.map(jour => (
                      <JourneyCard key={jour.contentful_id} {...jour} />
                    ))}
                  </>
                </div>
              </div>
            ))}
        </>
      )}
    </LayoutContainer>
  )
}

export default withTranslation()(Journeys)
